export default `{
  .
  .
  .
  "blocks": [{                              /* The block(s) that will generate in the pluton            */
    "block": "minecraft:emerald_ore",       /* NOTE: only 1 minimum block is required. Chances MUST     */
    "chance": 90                            /* Added up, must all to equal 100                          */
  }, {
    "block": "minecraft:diamond_ore",
    "chance": 10
  }],
  "samples": [{
    "block": "minecraft:poppy",
    "chance": 100
  }],
  .
  .
  .
}`;
